import React, {
    Fragment,
    useState,
    useCallback,
  } from 'react'
import styled from 'styled-components'
import UpsellPreview from './upsellPreview'
import Divider from '../../../components/divider'
import formatCurrency from '../../../helpers/formatCurrency'
const style = {
    giftCardInput: {
      "appearance": "none",
      "backgroundClip": "padding-box",
      "backgroundColor": "#fff",
      "border": "1px solid #d5d5d5",
      "borderRadius": "5px",
      "boxSizing": "border-box",
      "color": "#333",
      "display": "inline-block",
      "fontFamily": "-apple-system,system-ui,\"Segoe UI\",Roboto,Helvetica,Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",sans-serif",
      "fontSize": "14px",
      "fontWeight": "400",
      "lineHeight": "18.2px",
      "margin": "0",
      "padding": ".928571em .785714em",
      "transition": "all .2s ease-out",
      "wordBreak": "normal",
      "width": '100%',
    },
    giftCardApplyButton: {
      "cursor": "default",
      "background": "#c8c8c8",
      "boxShadow": "none",
      "boxSizing": "border-box",
      "border": "1px transparent solid",
      "borderRadius": "5px",
      "display": "inline-block",
      "width": "auto",
      "marginLeft": "0.8571428571em",
      "whiteSpace": "nowrap",
      "padding": "1em 1.7em",
      "flexShrink": 1,
    },
    giftCardApplyButtonContent: {    
      "position": "relative",
      "transition": "opacity 0.3s ease-in-out",
      "color": "white",
      "fontWeight": "500",
      "textAlign": "center",
      "textTransform": "none",
    },
    flex: {
      display: 'flex',
    },
    leftColumn: {
      width: '100%',
    },
    leftColumnContent: {
      "fontWeight": "400",
      "padding": 0,
      "fontFamily": "-apple-system, system-ui, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", sans-serif'\nfont-size: 14px",
      "fontWeight": "400",
      "color": "rgb(83, 83, 83)",
      "lineHeight": "18.2px",
      "textAlign": "left",
    },
    rightColumn: {
      flexShrink: 1,
    },
    rightColumnNextStep: {
      "textAlign": "right",
      "whiteSpace": "nowrap",
    },
    rightColumnContent: {
      "color": "rgb(50, 50, 50)",
      "display": "inline",
      "fontFamily": "-apple-system, system-ui, \"Segoe UI\", Roboto, Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", sans-serif",
      "fontSize": "14px",
      "fontWeight": "500",
    },
    nextStep: {
      "color": "#717171",
      "fontSize": "12px",
    }
  }
  const CartProductWrapper = styled.div`
    margin: 35px 0 20px 0;
    .cart-product-container {
      display: flex;
      font-size: 14px;
      justify-content: center;
      align-items: stretch;
      align-content: center;
      .cart-product-image-container {
        box-sizing: border-box;
        display: inline-block;
        width: 64px;
        height: 64px;
        border-radius: 5px;
        border: 1px solid #aaa;
        margin-right: 6px;
        background: white;
        align-self: center;
        .cart-product-image {
          object-fit: contain;
          object-position: center;
          height: 62px;
          width: 62px;
        }
      }
      .cart-product-title-container {
        box-sizing: border-box;
        display: flex;
        flex: 2;
        flex-direction: column;
        min-width: 100px;
        padding: 0 5px;
        text-align: left;
        padding-right: 12px;
        max-width: 218px;
        justify-content: space-between;
        .cart-product-title {
          box-sizing: border-box;
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 2px;
          line-height: 18px;
          text-align: left;
        }
        .cart-product-quantity {
          display: flex;
          gap: 2px;
          .border {
            border-radius: 4px;
            border: 1px solid #d5d5d5;
            padding: 4px 10px;
          }
        }
      }
      .cart-product-action-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px 0;
        .cart-product-price {
          box-sizing: border-box;
          color: #212b36;
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
          margin: 0;
          text-align: right;
        }
      }
      
    }
  `
const PAEPPreviewWrapper = styled.div`
  .order-summary {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0.7em 0;
      .order-summary-title {
          display: flex;
          gap: 15px;
      }
      .order-summary-price {
          font-size: 1.2857142857em;
          line-height: 1em;
          color: #333333;
          font-weight: 700;
      }
  }
  .cart-product-wrapper {
      margin: 35px 0 20px 0;
      .cart-product-container {
      display: flex;
      font-size: 14px;
      justify-content: center;
      align-items: stretch;
      align-content: center;
      .cart-product-image-container {
          box-sizing: border-box;
          display: inline-block;
          width: 64px;
          height: 64px;
          border-radius: 5px;
          border: 1px solid #aaa;
          margin-right: 6px;
          background: white;
          align-self: center;
          .cart-product-image {
          object-fit: contain;
          object-position: center;
          height: 62px;
          width: 62px;
          }
      }
      .cart-product-title-container {
          box-sizing: border-box;
          display: flex;
          flex: 2;
          flex-direction: column;
          min-width: 100px;
          padding: 0 5px;
          text-align: left;
          padding-right: 12px;
          max-width: 218px;
          .cart-product-title {
          box-sizing: border-box;
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 2px;
          line-height: 18px;
          text-align: left;
          }
          .cart-product-subtitle {
          box-sizing: border-box;
          font-size: 14px;
          line-height: 20px;
          text-align: left;
          }
      }
      .cart-product-action-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 5px 0;
          .cart-product-price {
          box-sizing: border-box;
          color: #212b36;
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
          margin: 0;
          text-align: right;
          }
      }
      
      }
  }
  .breadcrumb {
      padding: 15px 0;
      display: flex;
      justify-content: center;
      gap: 5px;
      span {
          font-size: 0.8571428571em;
          color: #737373;
          margin: 0;
      }
  }
  .payment {
      padding: 5px;
      .payment-content {
          display: flex;
          gap: 7px;
          flex-direction: row;
          padding-bottom: 15px;
          .payment-method-item {
              height: 35px;
              background: #E7E7E7;
              border-radius: 4px;
              width: 33.333%;
          }
      }
  }
  .alternative-payment-separator {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      font-size: 0.8571428571em;
      line-height: 1.4;
      text-align: center;
      text-transform: uppercase;
      color: #737373;
      padding: 15px 0;
      .alternative-payment-separator-content {

      }
  }
  .alternative-payment-separator::before, .alternative-payment-separator::after {
          content: '';
          display: inline-block;
          height: 1px;
          background-color: #e6e6e6;
          -webkit-box-flex: 1;
          -webkit-flex-grow: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
  }
  .contact-information {
      padding: 5px;
      h2 {
        padding-bottom: 10px;
      }
      input {
          width: 100%;
          border: 1px #A7ACB1 solid;
          background-clip: padding-box;
          border-radius: 5px;
          padding: 0.9285714286em 0.7857142857em;
      }
  }
  .shipping-information {
      padding: 5px;
      h2 {
        padding-bottom: 10px;
      }
      input {
          width: 100%;
          border: 1px #A7ACB1 solid;
          background-clip: padding-box;
          border-radius: 5px;
          padding: 0.9285714286em 0.7857142857em;
          margin-bottom: 10px;
      }
      .name {
        display: flex;
        flex-direction: row;
        gap: 10px;
        input {
          width: 50%;
        }
      }
      .address {
        display: flex;
        flex-direction: row;
        gap: 10px;
        input {
          width: 33.333%;
        }
      }
  }
  .continue-button-div {
    margin-bottom: 50px;
    padding: 5px;
    display: flex;
    .continue-button {
      background: #333333;
      color: #fff;
      padding: 20px;
      border-radius: 4px;
      border: none;
      margin-left: auto;
      font-weight: bold;
      font-size: 14px;
    }
  }
`
function CartDrawerDesktopPreview(props) {
  const locale = props.shopData && props.shopData.shopData && props.shopData.shopData.primary_locale || "en-US";
  const currency = props.shopData && props.shopData.shopData && props.shopData.shopData.currency || "USD";
  
    return (
      
        <Fragment>
          <div style={{background:"#4C4346",}}>
            <div style={{marginLeft:'50px', background:'#fff', padding: '0 25px 340px'}}>
              <h3 style={{fontSize:'20px', fontWeight:'700', padding:'25px 0'}}>Your Cart</h3>
             <Divider />
             <CartProductWrapper>
                <div className="cart-product-container">
                <div className="cart-product-image-container">
                    <img className="cart-product-image" src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/cart-product.png?alt=media&token=98f045eb-038f-45de-9d52-9b9b6d1db2b1" alt="" />
                </div>
                <div className="cart-product-title-container">
                    <p className="cart-product-title" >
                        The Classic Cobbler
                    </p>
                    <p className="cart-product-quantity" >
                        <div className="border">-</div>
                        <div className="border">1</div>
                        <div className="border">+</div>
                    </p>
                </div>
                <div className="cart-product-action-container">
                    <div>
                        <span className="cart-product-price">{formatCurrency(125.00, locale, currency)}</span>
                    </div>
                </div>
                </div>
            </CartProductWrapper>
            
            <UpsellPreview {...props} key={props.variants ? props.variants[0].id : null} type="Desktop"/>
            <Divider />
            <div style={{ ...style.flex, marginTop: 10 }}>
                <div style={style.leftColumn}>
                <span style={style.leftColumnContent}>Subtotal</span>
                </div>
                <div style={style.rightColumn}>
                <span style={style.rightColumnContent}>{formatCurrency(24.99, locale, currency)}</span>
                </div>
            </div>
            
            </div>
          </div>
        </Fragment>
    )
}
export default CartDrawerDesktopPreview;