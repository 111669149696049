import React, {
    Fragment,
    useState,
    useCallback,
    useEffect,
  } from 'react'
  
  import {
    Icon,
  } from "@shopify/polaris"
  
  import {
    DesktopMajor,
    MobileMajor
  } from '@shopify/polaris-icons';
  import CartDrawerDesktopPreview from './cartDrawerDesktopPreview'
  
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
  function CartDrawerPreview(props) {
    const [toggleValue, setToggleValue] = useState("Desktop")
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
      <div style={{maxHeight: windowDimensions.height ? windowDimensions.height-50 : '100%',}}>
        <div style={{display:"flex", flexDirection: "row", 	alignItems: "center", marginBottom: 15}}>
          <div style={{ display:"flex",  flexDirection: "row"}}>
            <div onClick={ () => { setToggleValue("Desktop") } } style={"Desktop" === toggleValue ? { borderRadius:'4px 0 0 4px', padding: '5px', borderRight:'none', background: '#A3A3A3', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)', cursor: 'pointer'} : {border: '1px solid rgba(0, 0, 0, 0.5)', borderRadius:'4px 0 0 4px', padding: '5px', borderRight:'none', cursor: 'pointer'}}>
              <Icon source={DesktopMajor} color="base"/>
            </div>
            <div onClick={ () => { setToggleValue("Mobile") } } style={"Mobile" === toggleValue ? { borderRadius:'0 4px 4px 0', padding: '5px', borderLeft:'none', background: '#A3A3A3', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)', cursor: 'pointer'} : {border: '1px solid rgba(0, 0, 0, 0.5)', borderRadius:'0 4px 4px 0', padding: '5px', borderLeft:'none', cursor: 'pointer'}}>
              <Icon source={MobileMajor} color="base"/>
            </div>
          </div>
          <div style={{textAlign: 'center', width: '100%'}}>
            <p style={{
              textAlign: 'center',
              color: '#aaa',
              fontSize: 13,
            }}>{"Desktop" === toggleValue ? "Desktop preview":"Mobile preview"}</p>
          </div>
        </div>  
        {"Desktop" === toggleValue ?
          <CartDrawerDesktopPreview {...props}/>
          :
          <CartDrawerDesktopPreview {...props}/>
        }
      </div>
    )
  }
  
  export default CartDrawerPreview;